
const WordpressToken = "c29tcHJhc29uZzpwWGRoIGM1bmkgS0dJZiBPU0xBIEhiSkEgSDZPTg==";


export const deleteMedia = async (mediaID) => {
    console.log("Deleting Media ID : " + mediaID)
    return new Promise(async (resolve, reject) => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", "Basic " + WordpressToken)
        const requestOptions = {
            method: "DELETE",
            headers: myHeaders,
            redirect: "follow"
        };
        await fetch(
            "https://gemnoble.com/wp-json/wp/v2/media/" + mediaID + "?force=1",
            requestOptions
        )
            .then((response) => response.text())
            .then((result) => {
                resolve(result);
                console.log(
                    "Media ID : " + JSON.parse(result).previous.id + " has Deleted"
                )
            })
            .catch((error) => {
                reject(error);
                console.error(error);
                console.log("Error on Deleting Media ID : " + mediaID);
            });
    })

}


export const getImagePlaceHolder = async () => {

    return new Promise(async (resolve, reject) => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", "Basic " + WordpressToken)
        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
            mode:"no-cors"
        };
        await fetch("https://gemnoble.com/wp-json/wp/v2/media/30", requestOptions)
            .then(
                (response) => response.text()
            )
            .then((result) => {
                
                resolve(result);
            })
            .catch((error) => {
                reject(error);
                console.error(error);

            });
    })

}

const WP_token = "c29tcHJhc29uZzpwWGRoIGM1bmkgS0dJZiBPU0xBIEhiSkEgSDZPTg==";

export const uploadImages_new = (imageFilesSelected,uploadStatus,uploadMessage) => {
    return new Promise(async (resolve,reject)=>{
      const ImagesList = [];
      uploadStatus(true);
      for (let i = 0; i < imageFilesSelected.length; i++) {
        uploadMessage("Uploading Image : " + imageFilesSelected[i].name);
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "image/jpeg");
          myHeaders.append(
              "Content-Disposition",
              "form-data; filename=\"" + imageFilesSelected[i].name + "\""
          );
          myHeaders.append(
              "Authorization",
              "Basic "+ WP_token
          );
          var requestOptions = {
              method: 'POST',
              headers: myHeaders,
              body: imageFilesSelected[i],
              redirect: 'follow'
          };
          await fetch("https://gemnoble.com/wp-json/wp/v2/media", requestOptions)
              .then(
                  response => response.text()
              )
              .then(result => {
                  ImagesList.push(JSON.parse(result))
                  console.log(
                      "Uploaded : " + imageFilesSelected[i].name + " URL:" + JSON.parse(result).media_details.sizes.woocommerce_thumbnail.source_url
                  )
              })
              .catch(error => reject(error));
      }
      
      resolve(ImagesList);
      uploadStatus(false);
      uploadMessage("Uploaded Image done");
    })
  }

  export const uploadVideos_new = (videoFilesSelected,uploadStatus,uploadMessage) => {
    return new Promise(async (resolve,reject)=>{
      const VideosList = [];
      uploadStatus(true);
      for (let i = 0; i < videoFilesSelected.length; i++) {

        uploadMessage("Uploading Video : " + videoFilesSelected[i].name)
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "image/jpeg");
          myHeaders.append(
              "Content-Disposition",
              "form-data; filename=\"" + videoFilesSelected[i].name + "\""
          );
          myHeaders.append(
              "Authorization",
              "Basic c29tcHJhc29uZzpwWGRoIGM1bmkgS0dJZiBPU0xBIEhiSkEgSDZPTg=="
          );
          var requestOptions = {
              method: 'POST',
              headers: myHeaders,
              body: videoFilesSelected[i],
              redirect: 'follow'
          };
          await fetch("https://gemnoble.com/wp-json/wp/v2/media", requestOptions)
              .then(
                  response => response.text()
              )
              .then(result => {

                  VideosList.push(JSON.parse(result))
                  console.log(
                      "Uploaded : " + videoFilesSelected[i].name + " URL:" + JSON.parse(result).source_url
                  )

              })
              .catch(error => reject(error));

      }
      resolve(VideosList);
      uploadStatus(false);
      uploadMessage("Uploaded Video done");

    })
  }

  export const uploadCerts_new = (certimgFilesSelected,uploadStatus,uploadMessage) => {
    return new Promise(async (resolve,reject)=>{
      const CertsList = [];
      uploadStatus(true);
      for (let i = 0; i < certimgFilesSelected.length; i++) {

        uploadMessage("Uploading Certificate : " + certimgFilesSelected[i].name)
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "image/jpeg");
          myHeaders.append(
              "Content-Disposition",
              "form-data; filename=\"" + certimgFilesSelected[i].name + "\""
          );
          myHeaders.append(
              "Authorization",
              "Basic c29tcHJhc29uZzpwWGRoIGM1bmkgS0dJZiBPU0xBIEhiSkEgSDZPTg=="
          );
          var requestOptions = {
              method: 'POST',
              headers: myHeaders,
              body: certimgFilesSelected[i],
              redirect: 'follow'
          };
          await fetch("https://gemnoble.com/wp-json/wp/v2/media", requestOptions)
              .then(
                  response => response.text()
              )
              .then(result => {
                CertsList.push(JSON.parse(result))
                console.log(
                      "Uploaded : " + certimgFilesSelected[i].name + " URL:" + JSON.parse(result).media_details.sizes.woocommerce_thumbnail.source_url
                  )

              })
              .catch(error => reject(error));

      }
      resolve(CertsList);
      uploadStatus(false);
      uploadMessage("Uploaded Certificate done");

    })
  }