import React, {useState, useEffect} from 'react';
import {json, useParams} from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import {Button, Grid, Typography} from '@mui/material';
import TextField from '@mui/material/TextField';

import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import InputAdornment from '@mui/material/InputAdornment';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Card from '@mui/joy/Card';
import CardCover from '@mui/joy/CardCover';
import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';

import SaveIcon from '@mui/icons-material/Save';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import {
    sellerLists,
    gemtypeLists,
    shapeLists,
    colorLists,
    clarityLists,
    originLists,
    treatmentLists,
    certs
} from '../utils/Utils';
import Swal from 'sweetalert2';
import {useNavigate} from "react-router-dom";
import { deleteMedia } from '../functions/wordpressAPI';
import { gemDataBaseUpdate } from '../functions/dataBaseAPI';
import {CreateTitle,CreateParagrahp,CreateWooDescription,createXmlEbay} from '../functions/functions';
import { getImagePlaceHolder } from '../functions/wordpressAPI';


export default function GemsUpdate() {
    const naVigate = useNavigate();

    const [isUploading,setIsuploading] = useState(false);
    const [status,setStatus] = useState('');

    const {id} = useParams();

    useEffect(() => {
        getGem();
        

    }, [id])

    const getGem = () => {
        fetch("https://app.gemnoble.com:2005/gems/" + id, {
            method: 'GET',
            redirect: 'follow'
        })
            .then(
                response => response.json()
            )
            .then(result => {
                if (result['status'] === 'ok') {

                    //setId(result['gem']['id'])
                    setType(result['gem']['type'])
                    setWeight(result['gem']['weight'])
                    setShape(result['gem']['shape'])
                    setWidth(result['gem']['width'])
                    setLength(result['gem']['length'])
                    setDepth(result['gem']['depth'])
                    setColor(result['gem']['color'])
                    setClarity(result['gem']['clarity'])
                    setOrigin(result['gem']['origin'])
                    setTreatment(result['gem']['treatment'])
                    setCertificate(result['gem']['certificate'])
                    setSeller(result['gem']['seller'])
                    setImages(result['gem']['images'])
                    setVideos(result['gem']['videos'])
                    setCertimgs(result['gem']['certimgs'])
                    setPrice(result['gem']['price'])
                    setPriceOption(result['gem']['priceOption'])
                    setWoo(result['gem']['woo'])
                    setTitle(result['gem']['title'])
                    setDescription(result['gem']['description'])
                    

                    setImageDisplay(result['gem']['images'])
                    setVideoDisplay(result['gem']['videos'])
                    setCertDisplay(result['gem']['certimgs'])

                    

                }
            })
            .catch(error => console.log('error', error));
    }

    const isTitleMorethan80 = (title) => {
        if(title.length>80){
            return true
        }else{
            return false
        }
    }

    const handleSubmit = event => {
        event.preventDefault();
        if(isTitleMorethan80(title)){
            Swal.fire({
                icon: "error",
                title: "Title more than 80",
                text: 'Please edit title '
              })
            
        }else{
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        setIsuploading(true);
        setStatus('Saving...')
        var raw = JSON.stringify({
            "id": id,
            "type": type,
            "weight": weight,
            "shape": shape,
            "width": width,
            "length": length,
            "depth": depth,
            "color": color,
            "clarity": clarity,
            "origin": origin,
            "treatment": treatment,
            "certificate": certificate,
            "seller": seller,
            "images": images,
            "videos": videos,
            "certimgs": certimgs,
            "price":price,
            "priceOption":priceOption,
            "woo":woo,
            "title":title,
            "description":description
            
        });

        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch("https://app.gemnoble.com:2005/gems/update/", requestOptions)
            .then(
                response => response.json()
            )
            .then(result => {
                if (result['status'] === 'ok') {
                    setIsuploading(false);
                    //setStatus("Item "+result['gem'].id+ " is Updated.");
                    setStatus("");
                    setIsUpdated(true);
                    Swal.fire({
                        icon: "success",
                        text: result.message
                        
                      }).then((value)=>{
                        naVigate('/listing/'+gem.id);
                        
                      });
                }
                
            })
            .catch(error => console.log('error', error));
        }

    }

    const handleUpdate = () => {
        
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "id": id,
            "type": type,
            "weight": weight,
            "shape": shape,
            "width": width,
            "length": length,
            "depth": depth,
            "color": color,
            "clarity": clarity,
            "origin": origin,
            "treatment": treatment,
            "certificate": certificate,
            "seller": seller,
            "images": images,
            "videos": videos,
            "certimgs": certimgs,
            "price":price,
            "priceOption":priceOption,
            "woo":woo,
            "title":title,
            "description":description
        });

        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch("https://app.gemnoble.com:2005/gems/update/", requestOptions)
            .then(
                response => response.json()
            )
            .then(result => {
                alert(result['message'])
                if (result['status'] === 'ok') {
                    window.location.href = '/'
                }
            })
            .catch(error => console.log('error', error));

    }

    
    const [type, setType] = useState('');
    const [weight, setWeight] = useState('');
    const [shape, setShape] = useState('');
    const [width, setWidth] = useState('');
    const [length, setLength] = useState('');
    const [depth, setDepth] = useState('');
    const [color, setColor] = useState('');
    const [clarity, setClarity] = useState('');
    const [origin, setOrigin] = useState('');
    const [treatment, setTreatment] = useState('');
    const [certificate, setCertificate] = useState('');
    const [seller, setSeller] = useState('');
    const [images, setImages] = useState('');
    const [videos, setVideos] = useState('');
    const [certimgs, setCertimgs] = useState('');
    const [price, setPrice] = useState('');
    const [priceOption, setPriceOption] = useState('sale');
    const [woo, setWoo] = useState(null);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');

    const handlePriceOption = (event) => {
        setPriceOption(event.target.value);
      };
    

    const [isImagesPicked, setIsImagesPicked] = useState(false);
    const [isVideosPicked, setIsVideosPicked] = useState(false);
    const [isCertsPicked, setIsCertsPicked] = useState(false);
    const [isUpdated, setIsUpdated] = useState(false);

    const [imageFilesSelect, setImageFilesSelect] = useState([]);
    const [imageDisplay, setImageDisplay] = useState([]);
    const [videoFilesSelect, setVideoFilesSelect] = useState([]);
    const [videoDisplay, setVideoDisplay] = useState([]);
    const [certimgFilesSelect, setCertimgFileSelect] = useState([]);
    const [certDisplay, setCertDisplay] = useState([]);

    const [imagesLoading, setImagesLoading] = useState(false);
    const [videosLoading, seVideosLoading] = useState(false);
    const [certsLoading, seCertsLoading] = useState(false);

    const [imagesLoadStatus, setImagesLoadStatus] = useState("");
    const [videosLoadStatus, seVideosLoadStatus] = useState("");
    const [certsLoadStatus, seCertsLoadStatus] = useState("");


    const  gem = {
        "id":id,
        "seller": seller,
        "type": type,
        "weight": weight,
        "shape": shape,
        "width": width,
        "length": length,
        "depth": depth,
        "color": color,
        "clarity": clarity,
        "origin": origin,
        "treatment": treatment,
        "certificate": certificate,
        "images": images,
        "videos": videos,
        "certimgs": certimgs,
        "price":price,
        "priceOption":priceOption,
        "woo":woo,
        "title":title,
        "description":description
    };

    const selectImages = (e) => {
        
        setImageFilesSelect(Array.prototype.slice.call(e.target.files))
        if(e.target.files.length<=0){
            setIsImagesPicked(false);
        }else{
            setIsImagesPicked(true)
        }
    };

    const selectVideos = (e) => {
        setVideoFilesSelect(Array.prototype.slice.call(e.target.files))
        if(e.target.files.length<=0){
            setIsVideosPicked(false)
        }else{
            setIsVideosPicked(true)
        }
    };

    const selectCerts = (e) => {
        setCertimgFileSelect(Array.prototype.slice.call(e.target.files))
        if(e.target.files.length<=0){
            setIsCertsPicked(false)
        }else{
            setIsCertsPicked(true)
        }
    };



    const handleUploadImages = async () => {
        const ImagesList = [];
        for (let i = 0; i < imageFilesSelect.length; i++) {
            console.log("Start Upload : " + imageFilesSelect[i].name)
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "image/jpeg");
            myHeaders.append(
                "Content-Disposition",
                "form-data; filename=\"" + imageFilesSelect[i].name + "\""
            );
            myHeaders.append(
                "Authorization",
                "Basic c29tcHJhc29uZzpwWGRoIGM1bmkgS0dJZiBPU0xBIEhiSkEgSDZPTg=="
            );
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: imageFilesSelect[i],
                redirect: 'follow'
            };
            await fetch("https://gemnoble.com/wp-json/wp/v2/media", requestOptions)
                .then(
                    response => response.text()
                )
                .then(result => {
                    ImagesList.push(JSON.parse(result))
                    console.log(
                        "Uploaded : " + imageFilesSelect[i].name + " URL:" + JSON.parse(result).media_details.sizes.woocommerce_thumbnail.source_url
                    )
                })
                .catch(error => console.log('error', error));
        }
        console.log([
            ...images,
            ...ImagesList
        ]);

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "id": id,
            "type": type,
            "weight": weight,
            "shape": shape,
            "width": width,
            "length": length,
            "depth": depth,
            "color": color,
            "clarity": clarity,
            "origin": origin,
            "treatment": treatment,
            "certificate": certificate,
            "seller": seller,
            "images": [...images,...ImagesList],
            "videos": videos,
            "certimgs": certimgs,
            "price":price,
            "priceOption":priceOption,
            "woo":woo,
            "title":title,
            "description":description
        });

        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch("https://app.gemnoble.com:2005/gems/update/", requestOptions)
            .then(
                response => response.json()
            )
            .then(result => {
                //alert(result['message'])
                if (result['status'] === 'ok') {
                    ///window.location.href = '/update/'+id ;
                    setImages([
                        ...images,
                        ...ImagesList
                    ]);
                    setImageDisplay([
                        ...images,
                        ...ImagesList
                    ]);
                    setImageFilesSelect([])
                    setIsImagesPicked(false)
                }
            })
            .catch(error => console.log('error', error));

        
        
    };

    const handleUploadVideos = async () => {
        const VideosList = [];
        for (let i = 0; i < videoFilesSelect.length; i++) {
            console.log("Start Upload : " + videoFilesSelect[i].name)
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "image/jpeg");
            myHeaders.append(
                "Content-Disposition",
                "form-data; filename=\"" + videoFilesSelect[i].name + "\""
            );
            myHeaders.append(
                "Authorization",
                "Basic c29tcHJhc29uZzpwWGRoIGM1bmkgS0dJZiBPU0xBIEhiSkEgSDZPTg=="
            );
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: videoFilesSelect[i],
                redirect: 'follow'
            };
            await fetch("https://gemnoble.com/wp-json/wp/v2/media", requestOptions)
                .then(
                    response => response.text()
                )
                .then(result => {
                    VideosList.push(JSON.parse(result))
                    console.log(
                        "Uploaded : " + videoFilesSelect[i].name + " URL:" + JSON.parse(result).source_url
                    )
                })
                .catch(error => console.log('error', error));
        }

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "id": id,
            "type": type,
            "weight": weight,
            "shape": shape,
            "width": width,
            "length": length,
            "depth": depth,
            "color": color,
            "clarity": clarity,
            "origin": origin,
            "treatment": treatment,
            "certificate": certificate,
            "seller": seller,
            "images": images,
            "videos": [...videos,...VideosList],
            "certimgs": certimgs,
            "price":price,
            "priceOption":priceOption,
            "woo":woo,
            "title":title,
            "description":description
        });

        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch("https://app.gemnoble.com:2005/gems/update/", requestOptions)
            .then(
                response => response.json()
            )
            .then(result => {
                //alert(result['message'])
                if (result['status'] === 'ok') {
                    //window.location.href = '/update/'+id ;
                    setVideos([
                        ...videos,
                        ...VideosList
                    ])
                    setVideoDisplay([
                        ...videos,
                        ...VideosList
                    ]);
                    setVideoFilesSelect([]);
                    setIsVideosPicked(false);
                }
            })
            .catch(error => console.log('error', error));

        

    };

    const handleUploadCerts = async () => {
        const CertsList = [];
        for (let i = 0; i < certimgFilesSelect.length; i++) {
            console.log("Start Upload : " + certimgFilesSelect[i].name)
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "image/jpeg");
            myHeaders.append(
                "Content-Disposition",
                "form-data; filename=\"" + certimgFilesSelect[i].name + "\""
            );
            myHeaders.append(
                "Authorization",
                "Basic c29tcHJhc29uZzpwWGRoIGM1bmkgS0dJZiBPU0xBIEhiSkEgSDZPTg=="
            );
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: certimgFilesSelect[i],
                redirect: 'follow'
            };
            await fetch("https://gemnoble.com/wp-json/wp/v2/media", requestOptions)
                .then(
                    response => response.text()
                )
                .then(result => {
                    CertsList.push(JSON.parse(result))
                    console.log(
                        "Uploaded : " + certimgFilesSelect[i].name + " URL:" + JSON.parse(result).media_details.sizes.woocommerce_thumbnail.source_url
                    )
                })
                .catch(error => console.log('error', error));
        }
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "id": id,
            "type": type,
            "weight": weight,
            "shape": shape,
            "width": width,
            "length": length,
            "depth": depth,
            "color": color,
            "clarity": clarity,
            "origin": origin,
            "treatment": treatment,
            "certificate": certificate,
            "seller": seller,
            "images": images,
            "videos": videos,
            "certimgs": [...certimgs,...CertsList],
            "price":price,
            "priceOption":priceOption,
            "woo":woo,
            "title":title,
            "description":description
        });

        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch("https://app.gemnoble.com:2005/gems/update/", requestOptions)
            .then(
                response => response.json()
            )
            .then(result => {
                //alert(result['message'])
                if (result['status'] === 'ok') {
                    //window.location.href = '/update/'+id ;
                    setCertimgs([
                        ...certimgs,
                        ...CertsList
                    ]);
                    setCertDisplay([
                        ...certimgs,
                        ...CertsList
                    ]);
                    setCertimgFileSelect([]);
                    setIsCertsPicked(false)
                    
                }
            })
            .catch(error => console.log('error', error));

        
    };

    const handleDeleteImages = async () => {
        const imageToDelete = images.pop().id
        
        if (images.length <1){
        alert("Unnable to delete");
        } else {
            setImagesLoading(true)
            setImagesLoadStatus("Deleting Image : "+imageToDelete )
            await deleteMedia(imageToDelete).then(result=>{
                if(JSON.parse(result).deleted==true){
                    var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                var raw = JSON.stringify({
                    "id": id,
                    "type": type,
                    "weight": weight,
                    "shape": shape,
                    "width": width,
                    "length": length,
                    "depth": depth,
                    "color": color,
                    "clarity": clarity,
                    "origin": origin,
                    "treatment": treatment,
                    "certificate": certificate,
                    "seller": seller,
                    "images": images,
                    "videos": videos,
                    "certimgs": certimgs,
                    "price":price,
                    "priceOption":priceOption,
                    "woo":woo,
                    "title":title,
                    "description":description
                });
                var requestOptions = {
                    method: 'PUT',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };
                fetch("https://app.gemnoble.com:2005/gems/update/", requestOptions)
                    .then(
                        response => response.json()
                    )
                    .then(result => {
                        //alert(result['message'])
                        if (result['status'] === 'ok') {
                            //window.location.href = '/update/'+id ;
                            var newimagesDisplay = images.slice(0,images.length);
                            //setImages(newimagesDisplay);
                            //setImageDisplay(newimagesDisplay);
                            console.log(result)
                            setImagesLoading(false)
                            setImagesLoadStatus(imageToDelete + "is Deleted!" )
                            getGem();
                            Swal.fire({
                                icon: "success",
                                title: imageToDelete + "is Deleted!"
                                
                              })
                            
                            
                        }
                    })
                    .catch(error => console.log('error', error));
                    
            
                }
                });
                
        }
    
    }

    const handleDeleteVideos = async () => {
        if (videos.length <=1){
        alert("Unnable to delete");
        } else {
            await deleteMedia(videos.pop().id).then(result=>{
                if(JSON.parse(result).deleted==true){
                    var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                var raw = JSON.stringify({
                    "id": id,
                    "type": type,
                    "weight": weight,
                    "shape": shape,
                    "width": width,
                    "length": length,
                    "depth": depth,
                    "color": color,
                    "clarity": clarity,
                    "origin": origin,
                    "treatment": treatment,
                    "certificate": certificate,
                    "seller": seller,
                    "images": images,
                    "videos": videos,
                    "certimgs": certimgs,
                    "price":price,
                    "priceOption":priceOption,
                    "woo":woo,
                    "title":title,
                    "description":description
                });
                var requestOptions = {
                    method: 'PUT',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };
                fetch("https://app.gemnoble.com:2005/gems/update/", requestOptions)
                    .then(
                        response => response.json()
                    )
                    .then(result => {
                        //alert(result['message'])
                        if (result['status'] === 'ok') {
                            window.location.href = '/update/'+id ;
                        }
                    })
                    .catch(error => console.log('error', error));
                    setVideos(videos)
                    setVideoDisplay(videos);
            
                }
                });
        }
    
    }

    const handleDeleteCerts = async () => {
            await deleteMedia(certimgs.pop().id).then(result=>{
                if(JSON.parse(result).deleted==true){
                    var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                var raw = JSON.stringify({
                    "id": id,
                    "type": type,
                    "weight": weight,
                    "shape": shape,
                    "width": width,
                    "length": length,
                    "depth": depth,
                    "color": color,
                    "clarity": clarity,
                    "origin": origin,
                    "treatment": treatment,
                    "certificate": certificate,
                    "seller": seller,
                    "images": images,
                    "videos": videos,
                    "certimgs": certimgs,
                    "price":price,
                    "priceOption":priceOption,
                    "woo":woo,
                    "title":title,
                    "description":description
                });
                var requestOptions = {
                    method: 'PUT',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };
                fetch("https://app.gemnoble.com:2005/gems/update/", requestOptions)
                    .then(
                        response => response.json()
                    )
                    .then(result => {
                        //alert(result['message'])
                        if (result['status'] === 'ok') {
                            window.location.href = '/update/'+id ;
                        }
                    })
                    .catch(error => console.log('error', error));
                setCertimgs(certimgs);
                setCertDisplay(certimgs);
            
                }
                });
        
    }

    const handleRaplaceImages = async () => {

    }

    const handleRaplaceVideos = async () => {

    }

    const handleRaplaceCerts = async () => {

    }

 

    const consoleLog = async () => {
       console.log(gem); 
        }

    const gemsListing = id =>{
        window.location = '/listing/' + id 
    }




    return (

        <React.Fragment>
            <CssBaseline/>
            <Container maxWidth="sm" sx={{
                    p: 2
                }}>
                <Typography variant='h6' gutterBottom component='div'>
                    Update Gem ID : {id}
                </Typography>
                
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>

                        
                        <Grid item xs={12} sm={6}><TextField
                                id="weight"
                                value={weight}
                                type="number"
                                label="Gem Weight"
                                variant="outlined"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">Ct</InputAdornment>
                                }}
                                fullWidth={true}
                                required
                                onChange={(e) => setWeight(e.target.value)}/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label" required>Shape</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="shape-select"
                                    value={shape}
                                    label="Seller"
                                    required
                                    onChange={(e) => setShape(e.target.value)}>
                                    {
                                        shapeLists.map((shapelist) => (
                                            <MenuItem key={shapelist.key} value={shapelist.key}>{shapelist.value}</MenuItem>
                                        ))
                                    }

                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                id="width"
                                value={width}
                                type="number"
                                label="Width"
                                variant="outlined"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">mm.</InputAdornment>
                                }}
                                fullWidth={true}
                                required
                                onChange={(e) => setWidth(e.target.value)}/>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                id="length"
                                value={length}
                                type="number"
                                label="Length"
                                variant="outlined"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">mm.</InputAdornment>
                                }}
                                fullWidth={true}
                                required
                                onChange={(e) => setLength(e.target.value)}/>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                id="depth"
                                value={depth}
                                type="number"
                                label="Depth"
                                variant="outlined"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">mm.</InputAdornment>
                                }}
                                fullWidth={true}
                                required
                                onChange={(e) => setDepth(e.target.value)}/>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label" required>Color</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="color-select"
                                    value={color}
                                    label="Color"
                                    required
                                    onChange={(e) => setColor(e.target.value)}>
                                    {
                                        colorLists.map((colorlist) => (
                                            <MenuItem key={colorlist.key} value={colorlist.key}>{colorlist.value}</MenuItem>
                                        ))
                                    }

                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label" required>Clarity</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="clarity-select"
                                    value={clarity}
                                    label="Clarity"
                                    required
                                    onChange={(e) => setClarity(e.target.value)}>
                                    {
                                        clarityLists.map((colorlist) => (
                                            <MenuItem key={colorlist.key} value={colorlist.key}>{colorlist.value}</MenuItem>
                                        ))
                                    }

                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label" required>Origin</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="origin-select"
                                    value={origin}
                                    label="Origin"
                                    required
                                    onChange={(e) => setOrigin(e.target.value)}>
                                    {
                                        originLists.map((originlist) => (
                                            <MenuItem key={originlist.key} value={originlist.key}>{originlist.value}</MenuItem>
                                        ))
                                    }

                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label" required>Treathment</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="treathment-select"
                                    value={treatment}
                                    label="Treathment"
                                    required
                                    onChange={(e) => setTreatment(e.target.value)}>
                                    {
                                        treatmentLists.map(
                                            (heatlist) => (<MenuItem key={heatlist.key} value={heatlist.key}>{heatlist.value}</MenuItem>)
                                        )
                                    }

                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>

                            <Select
                                labelId="demo-multiple-checkbox-label"
                                id="certificate-multiple-checkbox"
                                label="Certificate"
                                multiple
                                fullWidth={true}
                                value={typeof certificate === 'string'
                                ? certificate.split(',')
                                : certificate}
                                onChange={(e) => {
                                    const {target: {
                                            value
                                        }} = e;
                                    setCertificate(
                                        // On autofill we get a stringified value.
                                            typeof value === 'string'
                                            ? value.split(',')
                                            : value,
                                    );
                                }}
                                input={<OutlinedInput label = "" />}
                                renderValue={(selected) => selected.join(', ')}>
                                {
                                    certs.map((cert) => (
                                        <MenuItem key={cert} value={cert}>
                                            <Checkbox checked={certificate.indexOf(cert) > -1}/>
                                            <ListItemText primary={cert}/>
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth >
                                <InputLabel htmlFor="outlined-adornment-amount">Price</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-amount"
                                    type="number"
                                    value={price}
                                    required
                                    onChange={e=>setPrice(e.target.value)}
                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                    label="Amount"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} >
                        <FormControl>
                        <FormLabel id="demo-controlled-radio-buttons-group">Price Option</FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={priceOption}
                            onChange={handlePriceOption}
                            required
                        >
                            <FormControlLabel value="open" control={<Radio />} label="Open Price" />
                            <FormControlLabel value="sale" control={<Radio />} label="Sale Price" />
                            
                        </RadioGroup>
                        </FormControl>

                        </Grid>

                        <Grid item xs={12}>
                            <Container
                                maxWidth="lg"
                                sx={{
                                    p: 2,
                                    border: '1px dashed grey'
                                }}>
                                <Typography variant='h6' gutterBottom component='div'>
                                    Images
                                </Typography>
                                <ImageList
                                    sx={{
                                        width: 500,
                                        height: 500,
                                        p: 2,
                                        border: '1px dashed grey'
                                    }}
                                    cols={3}
                                    rowHeight={164}>
                                    {
                                        imageDisplay.map((item) => (
                                            <ImageListItem key={item.slug}>
                                                <img src={item.media_details.sizes.woocommerce_thumbnail.source_url}/>
                                            </ImageListItem>
                                        ))
                                    }
                                    
                                </ImageList>
                                <Typography variant='h6' gutterBottom component='div'>
                                    Add Images
                                </Typography>
                                
                                {
                                    isImagesPicked
                                        ? (
                                            <ImageList
                                                sx={{
                                                    width: 500,
                                                    height: 500
                                                }}
                                                cols={3}
                                                rowHeight={164}>
                                                {
                                                    imageFilesSelect.map((item) => (
                                                        <ImageListItem key={item.name}>
                                                            <img src={URL.createObjectURL(item)}/>
                                                        </ImageListItem>
                                                    ))
                                                }
                                            </ImageList>
                                            

                                        )
                                        : (<div></div>)
                                }

                                <input
                                    type="file"
                                    name="file"
                                    multiple="multiple"
                                    onChange={selectImages}></input>

                                <Grid container spacing={1}>
                                    <Grid item xs={4}>
                                        <Box align="right">
                                            <Button
                                                variant="contained"
                                                fullWidth={true}
                                                disabled={!isImagesPicked}
                                                onClick={handleUploadImages}
                                                endIcon={<SendIcon />}>Add</Button>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Box align="right">
                                            <Button
                                                variant="contained"
                                                fullWidth={true}
                                                disabled={!isImagesPicked}
                                                onClick={handleUploadImages}
                                                endIcon={<SendIcon />}>Replace
                                            </Button>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Box align="right">
                                            <Button
                                                variant="contained"
                                                fullWidth={true}
                                                disabled={images.length<=0 || imagesLoading===true}
                                                color="error"
                                                onClick={handleDeleteImages}
                                                startIcon={<DeleteIcon />}>Delete</Button>
                                        </Box>
                                    </Grid>

                                </Grid>

                                <Grid item xs={12}>
                                    
                                    <Box sx={{ width: '100%' }}>
                                        {imagesLoading?(<Typography>{imagesLoadStatus}</Typography>):(<Typography></Typography>)}
                                    </Box>
                                    <Box sx={{ width: '100%' }}>
                                        {imagesLoading?(<LinearProgress />):(<div></div>)}
                                    </Box>

                                </Grid>
                                
                            
                       
                            </Container>
                        </Grid>

                        <Grid item xs={12}>
                            <Container
                                maxWidth="lg"
                                sx={{
                                    p: 2,
                                    border: '1px dashed grey'
                                }}>
                                <Typography variant='h6' gutterBottom component='div'>
                                    Videos
                                </Typography>
                                <Box
                                    component="ul"
                                    sx={{
                                        display: 'flex',
                                        gap: 2,
                                        flexWrap: 'wrap',
                                        p: 0,
                                        m: 0
                                    }}>

                                    {
                                        videoDisplay.map((item) => (
                                            <Card
                                                component="li"
                                                sx={{
                                                    width: 500,
                                                    height: 500
                                                }}
                                                key={item.id}>

                                                <CardCover>
                                                    <video autoPlay="autoPlay" loop="loop" muted="muted">
                                                        <source src={item.source_url} type="video/mp4"/>
                                                    </video>
                                                </CardCover>

                                            </Card>
                                        ))
                                    }
                                </Box>
                                <Typography variant='h6' gutterBottom component='div'>
                                    Add Videos
                                </Typography>
                                <input
                                    type="file"
                                    name="file"
                                    multiple="multiple"
                                    onChange={selectVideos}></input>

                                <Grid container spacing={1}>
                                    <Grid item xs={4}>
                                        <Box align="right">
                                        <Button variant="contained" fullWidth={true} disabled={!isVideosPicked} onClick={handleUploadVideos} endIcon={<SendIcon />}>Add</Button>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Box align="right">
                                            <Button
                                                variant="contained"
                                                fullWidth
                                                disabled={!isVideosPicked}
                                                onClick={handleUploadImages}
                                                endIcon={<SendIcon />}>Replace
                                            </Button>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={4}>
                                    <Box align="right">
                                    <Button  variant="contained" fullWidth={true} disabled={videos.length<=0} color="error" onClick={handleDeleteVideos} startIcon={<DeleteIcon />} >Delete</Button>
                                    </Box>
                                    </Grid>


                                    
                                    <Grid item xs={12}>
                                    
                                    <Box sx={{ width: '100%' }}>
                                        {videosLoading?(<Typography>{videosLoadStatus}</Typography>):(<Typography></Typography>)}
                                    </Box>
                                    <Box sx={{ width: '100%' }}>
                                        {videosLoading?(<LinearProgress />):(<div></div>)}
                                    </Box>

                                </Grid>

                                </Grid>
                                

                                {
                                    isVideosPicked
                                        ? (
                                            <Box
                                                component="ul"
                                                sx={{
                                                    display: 'flex',
                                                    gap: 2,
                                                    flexWrap: 'wrap',
                                                    p: 0,
                                                    m: 0
                                                }}>

                                                {
                                                    videoFilesSelect.map((item) => (
                                                        <Card
                                                            component="li"
                                                            sx={{
                                                                width: 500,
                                                                height: 500
                                                            }}
                                                            key={item.id}>

                                                            <CardCover>
                                                                <video autoPlay="autoPlay" loop="loop" muted="muted">
                                                                    <source src={URL.createObjectURL(item)} type="video/mp4"/>
                                                                </video>
                                                            </CardCover>

                                                        </Card>
                                                    ))
                                                }
                                            </Box>
                                            

                                        )
                                        : (<div></div>)
                                }
                                
                            
                            </Container>
                        </Grid>

                        <Grid item xs={12}>
                            <Container
                                maxWidth="lg"
                                sx={{
                                    p: 2,
                                    border: '1px dashed grey'
                                }}>
                                <Typography variant='h6' gutterBottom component='div'>
                                    Certificates
                                </Typography>
                                <Box
                                    component="ul"
                                    sx={{
                                        display: 'flex',
                                        gap: 2,
                                        flexWrap: 'wrap',
                                        p: 0,
                                        m: 0
                                    }}>
                                    {
                                        certDisplay.map((item) => (
                                            <Card
                                                component="li"
                                                sx={{
                                                    width: 500,
                                                    height: 500
                                                }}
                                                key={item.id}>
                                                <CardCover>
                                                    <img src={item.source_url} srcSet={item.source_url} loading="lazy" alt=""/>
                                                </CardCover>
                                            </Card>
                                        ))
                                    }
                                </Box>

                                <Typography variant='h6' gutterBottom component='div'>
                                    Add Certificates
                                </Typography>
                                <Grid container spacing={2}>
                                <Grid item xs={6}>
                                <Box align="right">
                                <input type="file" name="file" multiple="multiple" onChange={selectCerts}></input>
                                </Box>
                                </Grid>
                                {isCertsPicked?(<Box align="right">
                                <Button variant="contained" fullWidth={true}  onClick={handleUploadCerts} endIcon={<SendIcon />}>Clear</Button>
                                </Box>):(<Box align="right">
                                
                                </Box>)}
                                
                                </Grid>
                                
                                {
                                    isCertsPicked
                                        ? (
                                            <Box
                                                component="ul"
                                                sx={{
                                                    display: 'flex',
                                                    gap: 2,
                                                    flexWrap: 'wrap',
                                                    p: 0,
                                                    m: 0
                                                }}>

                                                {
                                                    certimgFilesSelect.map((item) => (
                                                        <Card
                                                            component="li"
                                                            sx={{
                                                                width: 500,
                                                                height: 500
                                                            }}>

                                                            <CardCover>
                                                                <img
                                                                    src={URL.createObjectURL(item)}
                                                                    srcSet={URL.createObjectURL(item)}
                                                                    loading="lazy"
                                                                    alt=""/>
                                                            </CardCover>

                                                        </Card>
                                                    ))
                                                }
                                            </Box>

                                        )
                                        : (<div></div>)
                                }
                                <Grid container spacing={1}>
                                    <Grid item xs={4}>
                                        <Box align="right">
                                        <Button variant="contained" fullWidth={true} disabled={!isCertsPicked} onClick={handleUploadCerts} endIcon={<SendIcon />}>Add</Button>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Box align="right">
                                            <Button
                                                variant="contained"
                                                fullWidth={true}
                                                disabled={!isCertsPicked}
                                                onClick={handleUploadImages}
                                                endIcon={<SendIcon />}>Replace
                                            </Button>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={4}>
                                    <Box align="right">
                                    <Button  variant="contained" fullWidth={true} disabled={certimgs.length<=0} color="error" onClick={handleDeleteCerts} startIcon={<DeleteIcon />}>Delete</Button>
                                    </Box>
                                    </Grid>

                                    <Grid item xs={12}>
                                    
                                    <Box sx={{ width: '100%' }}>
                                        {certsLoading?(<Typography>{certsLoadStatus}</Typography>):(<Typography></Typography>)}
                                    </Box>
                                    <Box sx={{ width: '100%' }}>
                                        {certsLoading?(<LinearProgress />):(<div></div>)}
                                    </Box>

                                    </Grid>

                                </Grid>

                                
                                

                            </Container>
                        </Grid>

                        <Grid item xs={12}>
                        <Box sx={{ width: '100%' }}>
                          <Typography>
                            {status}
                            </Typography>
                        </Box>
                        <Box sx={{ width: '100%' }}>
                          {isUploading?(<LinearProgress />):(<div></div>)}
                          
                        </Box>

                          
                        </Grid>

                        <Grid item xs={12}>
                            
                            <Container
                                maxWidth="lg"
                                sx={{
                                    p: 2,
                                    border: '1px dashed grey',
                                }}>
                                <Typography variant='h6' gutterBottom component='div'>
                                    Create title and description
                                </Typography>
                                <Container
                                maxWidth="lg"
                                sx={{
                                    p: 2,
                                    border: '0px dashed grey'
                                }}><Box
                                component="ul"
                                sx={{
                                    display: 'flex',
                                    gap: 2,
                                    flexWrap: 'wrap',
                                    p: 0,
                                    m: 0
                                }}>
                                    <TextField
                                    fullWidth={true}
                                    id="outlined-multiline-flexible"
                                    label="Title"
                                    multiline
                                    maxRows={4}
                                    error={isTitleMorethan80(title)}
                                    value={title}
                                    onChange={e=>{setTitle(e.target.value);}}
                                    />
                                    </Box></Container>

                                    <Container
                                maxWidth="lg"
                                sx={{
                                    p: 2,
                                    border: '0px dashed grey'
                                }}><Box
                                    component="ul"
                                    sx={{
                                        display: 'flex',
                                        gap: 2,
                                        flexWrap: 'wrap',
                                        p: 0,
                                        m: 0
                                    }}>
                                        <TextField
                                        fullWidth={true}
                                        id="outlined-multiline-static"
                                        label="Description"
                                        multiline
                                        rows={4}
                                        value={description}
                                        onChange={e=>{setDescription(e.target.value);}}
                                        />
                                        </Box></Container>

                                
                        
                            </Container>
                        </Grid>

                        <Grid item xs={12}>
                            {
                                isUpdated?
                                (<div></div>):
                                (<Button type="submit" variant='contained' fullWidth={true} startIcon={<SaveIcon />}>Save</Button>)
                            
                            }


                            {true?(<div></div>):(<Button variant='contained' fullWidth={true} onClick={e=>{e.preventDefault();window.location.href = '/listing/'+gem.id;}}>Goto Listing</Button>)}

                            
                        </Grid>
                       

                       
                        <Grid item xs={12}>
                        {true?(<div></div>):(<Button
                                onClick={consoleLog}
                                variant='contained'
                                fullWidth={true}
                                

                                >CONSOLELOG</Button>)}
                            
                        </Grid>

                    </Grid>
                </form>
            </Container>
        </React.Fragment>
    );
}