import React, { useState, useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Avatar from '@mui/material/Avatar';
import Link from '@mui/material/Link';
import ButtonGroup from '@mui/material/ButtonGroup';
import Pagination from '@mui/material/Pagination'; // Import Pagination component
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import { deleteMedia } from '../functions/wordpressAPI';

export default function Gems() {
    const navigate = useNavigate();
    const [isLoaded, setIsLoaded] = useState(true);
    const [user, setUser] = useState([]);
    const [items, setItems] = useState([]);

    // State for pagination
    const [page, setPage] = useState(1);
    const [rowsPerPage] = useState(10); // Rows per page, you can make this configurable

    useEffect(() => {
        UserGet();
    }, []);

    const gemGet = () => {
        fetch("https://app.gemnoble.com:2005/gems")
          .then(res => res.json())
          .then(
            (result) => {
              setItems(result);
            }
          );
    }

    const UserGet = () => {
      const token = localStorage.getItem('token');
      const myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer " + token);

      const requestOptions = {       
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      };

      fetch("https://www.melivecode.com/api/auth/user", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.status === 'ok') {
            setIsLoaded(false);
            gemGet();
          } else {
            Swal.fire({
              icon: "error",
              text: result.message
            }).then(() => {
              navigate('/signin');
            });
          }
        })
        .catch((error) => console.error(error));
    }

    const handleDeleteImages = async (images) => {
        for (let i = 0; i < images.length; i++) {
            await deleteMedia(images[i].id);
        }
        console.log("Deleted images");
    };

    const handleDeleteVideos = async (videos) => {
        for (let i = 0; i < videos.length; i++) {
            await deleteMedia(videos[i].id);
        }
        console.log("Deleted videos");
    };

    const handleDeleteCerts = async (certs) => {
        for (let i = 0; i < certs.length; i++) {
            await deleteMedia(certs[i].id);
        }
        console.log("Deleted certimgs");
    };

    const UserDelete = (gem) => {
      if (gem.woo == null) {
        handleDeleteImages(gem.images);
        handleDeleteVideos(gem.videos);
        handleDeleteCerts(gem.certimgs);
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify({ "id": gem.id });
        var requestOptions = {
          method: 'DELETE',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };
        fetch("https://app.gemnoble.com:2005/gems/delete/", requestOptions)
          .then(response => response.json())
          .then(result => {
            if (result['status'] === 'ok')
              UserGet();
          })
          .catch(error => console.log('error', error));
      } else if (gem.woo != null) {
        Swal.fire({
          icon: "error",
          text: "Unable to Delete Because it is now Live on Website Link: " + gem.woo.permalink
        });
      }
    }



    // Pagination logic
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const paginatedItems = items.slice((page - 1) * rowsPerPage, page * rowsPerPage);

    return (
        <React.Fragment>
            <CssBaseline />
            <Container maxWidth="lg" sx={{ p: 2 }}>
                <Paper sx={{ p: 2 }}>
                    <Box display="flex">
                        <Box sx={{ flexGrow: 1 }}>
                            <Typography variant="h6" gutterBottom>
                                Gems
                            </Typography>
                        </Box>
                    
                        <Box>
                            <Link href="create">
                                <Button variant="contained">Create</Button>
                            </Link>
                        </Box>
                    </Box>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>SKU</TableCell>
                                    <TableCell align="center">Avatar</TableCell>
                                    <TableCell align="right">Type</TableCell>
                                    <TableCell align="right">CTW</TableCell>
                                    <TableCell align="right">Shape</TableCell>
                                    <TableCell align="right">Dimension</TableCell>
                                    <TableCell align="right">Cert</TableCell>
                                    <TableCell align="center">Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {paginatedItems.map((row) => (
                                    <TableRow
                                        key={row.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {row.id}
                                        </TableCell>
                                        <TableCell align="center">
                                            <Box display="flex" justifyContent="center">
                                                <Avatar alt={row.username} src={row.images[0].media_details.sizes.thumbnail.source_url} />
                                            </Box>
                                        </TableCell>
                                        <TableCell align="right">{row.type}</TableCell>
                                        <TableCell align="right">{row.weight}</TableCell>
                                        <TableCell align="right">{row.shape}</TableCell>
                                        <TableCell align="right">{row.width + " x " + row.length + " x " + row.depth}</TableCell>
                                        <TableCell align="right">{row.certificate.toString()}</TableCell>
                                        <TableCell align="right">
                                            <ButtonGroup variant="outlined" aria-label="outlined button group">
                                                <Button onClick={() => window.location = '/update/' + row.id}>Edit</Button>
                                                <Button onClick={() => window.location = '/listing/' + row.id}>Listing</Button>
                                                <Button disabled={!(row.woo===null)} onClick={() => UserDelete(row)} >Delete</Button>
                                            </ButtonGroup>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    
                    {/* Pagination Controls */}
                    <Box display="flex" justifyContent="center" marginTop={2}>
                        <Pagination
                            count={Math.ceil(items.length / rowsPerPage)}
                            page={page}
                            onChange={handleChangePage}
                            color="primary"
                        />
                    </Box>
                </Paper>
            </Container>
        </React.Fragment>
    );
}
